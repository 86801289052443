import { datadogRum } from "@datadog/browser-rum";
import { atom } from "jotai";
import Cookies from "js-cookie";
import { trackEvent } from "@/features/Analytics/helpers/trackEvent";
import { LaunchDarklyAnalyticsEvents } from "@/features/LaunchDarkly/analytics";
import { launchDarklyAtom } from "@/features/LaunchDarkly/atoms/launchDarklyAtoms";
import { launchDarklyDevOverridesAtom } from "@/features/LaunchDarkly/atoms/launchDarklyOverridesAtoms";
import type { FeatureFlag } from "@/features/LaunchDarkly/helpers/allFeatureFlags";
import { allFeatureFlags } from "@/features/LaunchDarkly/helpers/allFeatureFlags";
import { casedFeatureFlagsMap } from "@/features/LaunchDarkly/helpers/camelize";
import type {
  CamelizedFeatureFlag,
  CamelizedFeatureFlagSet,
} from "@/features/LaunchDarkly/helpers/camelize";
import { inSyntheticTest } from "@/helpers/environment";

export const featureFlagsAtom = atom((get) => {
  // Dev overrides are only available in non-production environments (staging, local, and dev PE), so we can skip it in prod
  const devOverrides =
    process.env.APP_ENV !== "prod" ? get(launchDarklyDevOverridesAtom) : {};

  const { client, flagsCache } = get(launchDarklyAtom);

  const camelFlags = allFeatureFlags.reduce(
    (flagSet, [flag, defaultValue]) => ({
      ...flagSet,
      [casedFeatureFlagsMap[flag] as CamelizedFeatureFlag]: defaultValue,
    }),
    {} as CamelizedFeatureFlagSet,
  );
  return new Proxy(camelFlags, {
    get: (_, flag: CamelizedFeatureFlag) => {
      /**
       * Whenever a user accesses one of the flags, proxy that request to
       * the LaunchDarkly client to get the flag value and send metrics.
       *
       * If the LaunchDarkly client returns `undefined`, it generally means
       * that the LaunchDarkly service is down and the bootstrapped values
       * in localStorage are unavailable. In this case, return the default
       * value specified in order to prevent an unexpected scenario where
       * the value doesn't match the expected type.
       *
       * As the hook that consumes this atom can re-render multiple times,
       * we are caching the value of the variation in-memory to avoid
       * requesting it multiple times which results in some performance overhead.
       * flagsCache is managed in jotai atom instead of globally to support reset
       * from LD mocks util in tests.
       */
      if (flagsCache[flag] === undefined) {
        const flagValue = client?.variation(
          casedFeatureFlagsMap[flag] as FeatureFlag,
        );

        flagsCache[flag] = flagValue;

        if (flagValue !== undefined) {
          trackEvent(
            LaunchDarklyAnalyticsEvents.FeatureFlagValueCached,
            {
              flagName: flag,
              flagValue,
              disableAmplitudeDestinationOnSegment: true,
            },
            undefined,
            ["segment"],
            ["snowflake"],
          );
        }
      }

      const flagEvaluation =
        getFeatureFlagFromCookie(flag) ??
        devOverrides?.[casedFeatureFlagsMap[flag] as FeatureFlag] ??
        flagsCache[flag] ??
        camelFlags[flag];

      if (datadogFeatureFlagAllowlist.has(flag)) {
        datadogRum.addFeatureFlagEvaluation(flag, flagEvaluation);
        datadogRum.setGlobalContextProperty(
          `featureFlag.${flag}`,
          flagEvaluation,
        );
      }

      return flagEvaluation;
    },
  });
});

function getFeatureFlagFromCookie<Flag extends CamelizedFeatureFlag>(
  flag: Flag,
): CamelizedFeatureFlagSet[Flag] | undefined {
  // We only want to read FFs from cookies if 1) we're in a synthetic test and 2) we're not in prod
  if (!inSyntheticTest() || process.env.APP_ENV === "prod") {
    return undefined;
  }

  try {
    const cookie = Cookies.get(`featureFlag.ld.${flag}`);
    if (cookie) {
      return JSON.parse(cookie);
    }
  } catch {}

  return undefined;
}

/**
 * An allowlist of FFs to be added as metadata to Datadog RUM. It's meant to include FFs for the
 * Data Fetching Revamp project, as we need to compare metrics across variants for migrated routes.
 */
const datadogFeatureFlagAllowlist: ReadonlySet<CamelizedFeatureFlag> = new Set([
  "showWalletV3",
  "enableRelayApolloSyncing",
  "teamDatagridEnabled",
  "logoutOnAccessDenied",
  // without satisfies we don't get autocompletion when typing FF names
] satisfies CamelizedFeatureFlag[]);
